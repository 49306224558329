$(document).ready(function(){
    var canvas = document.getElementById("canvas");
var footer = document.getElementById("footer")

var c = canvas.getContext('2d');
var arr = [];
var strr = [];
function stars(xs,ys,rs,speedXs,speedYs,canW,canH) {
    this.x = xs;
    this.y = ys;
    this.r = rs;
    this.speedX = speedXs;
    this.speedY = speedYs;
    this.canW = canW;
    this.canH = canH;
    this.draw = function () {
        c.beginPath();
        c.strokeStyle = "rgba(255,255,255,0.5)";
        c.fillStyle = "rgba(255,255,255,0.5)";
        c.arc(this.x,this.y,this.r,0,Math.PI *2 ,false);
        c.fill();
        c.stroke();
    }
    this.move = function () {

        if(this.y > this.canH + 100)
        {
            this.speedY = -this.speedY;
        }else if(this.y < 0 - 100){
            this.speedY = Math.abs(this.speedY)
        }
        if(this.x > this.canW + 100)
        {
            this.speedX = -this.speedX;
        }else if ((this.x < 0 - 100)){
            this.speedX = Math.abs(this.speedX)
        }
        this.x += this.speedX ;
        this.y += this.speedY;
        this.draw();
    }
}
/*CIRC*/
function circ(x,y,r,speedX,speedY,canW,canH) {
    this.x = x;
    this.y = y;
    this.r = r;
    this.speedX = speedX;
    this.speedY = speedY;
    this.canW = canW;
    this.canH = canH;
    this.draw = function () {
        c.beginPath();
        c.strokeStyle = "rgba(255,255,255,0.3)";
        c.fillStyle = "rgba(255,255,255,0.3)";
        c.arc(this.x,this.y,this.r,0,Math.PI *2 ,false);
        c.fill();
        c.stroke();
    }
    this.move = function () {

        if(this.y > this.canH + 100)
        {
            this.speedY = -this.speedY;
        }else if(this.y < 0 - 100){
            this.speedY = Math.abs(this.speedY)
        }
        if(this.x > this.canW + 100)
        {
            this.speedX = -this.speedX;
        }else if ((this.x < 0 - 100)){
            this.speedX = Math.abs(this.speedX)
        }
        this.x += this.speedX ;
        this.y += this.speedY;
        this.draw();
    }
    this.radar = function (obj) {
        for(var i = 0;i<obj.length-1;i++){
            for(var j = 0;j<obj.length-1;j++){
               if((obj[i].x + 100 > obj[j].x && obj[i].x - 100 < obj[j].x) && (obj[i].y + 100 > obj[j].y && obj[i].y - 100 < obj[j].y)){
                   c.beginPath();
                   c.strokeStyle = "rgba(255,255,255,0.2)";
                   c.moveTo(obj[i].x,obj[i].y);
                   c.lineTo(obj[j].x,obj[j].y);
                   c.stroke();
               }
            }
        }
    }
}
function moveX() {
    var f = document.getElementById("footer");
    var canvasWidth = f.offsetWidth;
    var canvasHeight = f.offsetHeight - 22;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    c.clearRect(0,0,canvas.width,canvas.height);
    for(var j=0;j<50;j++){
        var xs = Math.floor(Math.random() * canvasWidth);
        var ys = Math.floor(Math.random() * canvasHeight);
        var rs = Math.floor(Math.random() * 3);
        if (i%2==0){
            var speedYs = Math.random();
            var speedXs = -Math.random();
        }else{
            var speedYs = -Math.random();
            var speedXs = Math.random();
        }
        var star = new stars(xs,ys,rs,speedXs,speedYs,canvasWidth,canvasHeight);
        strr.push(star);
        strr[j].move();
    }
    for(var i=0;i<110;i++){
        var x = Math.floor(Math.random() * canvasWidth);
        var y = Math.floor(Math.random() * canvasHeight);
        var r = RND(2,4);
        if (i%2==0){
            var speedY = Math.random();
            var speedX = -Math.random();
        }else{
            var speedY = -Math.random();
            var speedX = Math.random();
        }
        var cir = new circ(x,y,r,speedX,speedY,canvasWidth,canvasHeight);
        arr.push(cir);
        arr[i].move();
    }
    arr = clearArray(arr);
    cir.radar(arr);
}
setInterval(moveX,20);
function RND(startNumber,endNumber) {
   var number = startNumber + Math.floor(Math.random() * endNumber-startNumber);
    if(number != 0){
        return number;
    }
}
function clearArray(array) {
    for(var d = 0;d<arr.length-1;d++){
        array.pop();
    }
    return array;
}
$(document).on('click','.header__mobile__item__icon i',function(){
    var $this = $(this),
        parent = $this.parents('.header__mobile__item');
        parent.toggleClass('active');
        if(parent.hasClass('active'))
        {
            $this.removeClass('fa-solid fa-bars');
            $this.addClass('fa-solid fa-xmark');
        }
        else
        {
            $this.removeClass('fa-solid fa-xmark');
            $this.addClass('fa-solid fa-bars');
        }
        $('.header__mobile__menu').slideToggle('slow')
        

})
});